import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class WindowEventsService {
  combinedEvents$: Observable<boolean | Event>;
  constructor() {
    const beforeUnload$ = fromEvent(window, 'beforeunload');
    const visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
      map(() => document.hidden)
    );
    this.combinedEvents$ = merge(beforeUnload$, visibilityChange$);
  }
}
