import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store
} from '@ngxs/store';
import { KibanaService } from '../../services/requests/kibana.service';
import { SessionState } from '../session/session.state';
import {
  SaveActivityTimeAction,
  SetStartActivityTimeAction,
  UpdateLastActivityTimeAction
} from './activity.actions';

export interface ActivityStateModel {
  startActivityTime: Date;
  lastActivityTime?: Date;
}

const ACTIVITY_STATE_TOKEN = new StateToken<ActivityStateModel>('activity');

@State<ActivityStateModel>({
  name: ACTIVITY_STATE_TOKEN,
  defaults: {
    startActivityTime: new Date()
  }
})
@Injectable()
export class ActivityState {
  constructor(private kibanaService: KibanaService, private store: Store) {}

  @Selector() static startActivity({ startActivityTime }: ActivityStateModel) {
    return startActivityTime;
  }

  @Action(SetStartActivityTimeAction)
  setStartActivityTimeAction({
    patchState,
    getState
  }: StateContext<ActivityStateModel>): void {
    const { lastActivityTime } = getState();
    if (!lastActivityTime) {
      return;
    }
    const now = new Date();
    const diff = (now.getTime() - lastActivityTime.getTime()) / 1000 / 60;
    if (diff < 30) {
      return;
    }

    patchState({
      startActivityTime: now
    });
  }

  @Action(SaveActivityTimeAction) saveActivityTimeAction(
    { getState }: StateContext<ActivityStateModel>,
    { reason }: SaveActivityTimeAction
  ) {
    const identifier = this.store.selectSnapshot(SessionState.identifier);
    if (!identifier) {
      console.warn('[SaveActivityTimeAction]: Identifier not found.');
      return;
    }

    const { startActivityTime } = getState();
    const now = new Date();
    const minutes = (now.getTime() - startActivityTime.getTime()) / 1000 / 60;
    const seconds = ((now.getTime() - startActivityTime.getTime()) / 1000) % 60;
    return this.kibanaService.postKibanaLog({
      trackingId: 'activity-time',
      guestIdentifier: identifier,
      objectData: {
        time: Number(`${Math.floor(minutes)}.${Math.round(seconds)}`),
        reason
      }
    });
  }

  @Action(UpdateLastActivityTimeAction) updateLastActivityTimeAction({
    patchState
  }: StateContext<ActivityStateModel>) {
    patchState({
      lastActivityTime: new Date()
    });
  }
}
