import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  LivErrorResponse,
  LivSuccessResponse
} from '../models/liv-response-protocol.model';

const whiteList = ['/assets', '/user/avatar'];

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<never>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse | unknown>> {
    const whiteListed = new RegExp(whiteList.join('|')).test(req.url);
    const request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    if (whiteListed || req.headers.has('x-skip-interceptor')) {
      const headers = req.headers.delete('x-skip-interceptor');
      const clonedRequest = req.clone({ headers });
      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }
}
