import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlerService {
  log(error: any) {
    if (error instanceof HttpErrorResponse) {
      console.error('Server Error: ', error.message);

      return;
    }
    console.error(error);
  }
}
