/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  finalize,
  first,
  switchMap,
  take,
  throwError
} from 'rxjs';
import { requestNeedsAuthToken } from './token.interceptor.inceptor';
import {
  SessionState,
  SessionStateModel
} from '../store/session/session.state';
import { RefreshTokenAction } from '../store/session/session.actions';
import { AuthResponseModel } from '../models/auth.model';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!requestNeedsAuthToken(req)) {
          return throwError(() => error);
        }
        return this.handle401Error(req, next, error);
      })
    );
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    const statusCodesAuthenticationError = [HttpStatusCode.Unauthorized];
    const currentStatusCodeIsNotTokenExpirationError =
      !statusCodesAuthenticationError.includes(error.status);

    if (currentStatusCodeIsNotTokenExpirationError) {
      return throwError(() => error);
    }

    const accessToken =
      this.store.selectSnapshot(SessionState.accessToken);

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.store.dispatch(new RefreshTokenAction()).pipe(
        switchMap(() => this.store.select(SessionState.session)),
        first(
          (newAuthentication) =>
            !!newAuthentication &&
            newAuthentication.accessToken !== accessToken
        ),
        switchMap((newAuthentication) => {
          this.refreshTokenSubject.next(newAuthentication.accessToken);
          return next.handle(req);
        }),
        catchError((requestErrorWithNewToken: HttpErrorResponse) => {
          this.refreshTokenSubject.error(error);
          return throwError(() => requestErrorWithNewToken);
        }),
        finalize(() => (this.isRefreshing = false))
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap(() => next.handle(req))
    );
  }
}
