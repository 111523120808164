import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone, inject } from '@angular/core';
import { ErrorHandlerService } from './services/error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone) {}

  handleError(error: Error | HttpErrorResponse | any): void {
    const errorService = inject(ErrorHandlerService);

    this.zone.run(() => {
      errorService.log(error);
    });
  }
}
