import { Component } from '@angular/core';
import { ConfigService } from './core/services/config.service';
import { of } from 'rxjs';
import { Store } from '@ngxs/store';
import { WindowEventsService } from './core/services/window-events.service';
import {
  SaveActivityTimeAction,
  SetStartActivityTimeAction,
  UpdateLastActivityTimeAction
} from './core/store/activity/activity.actions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Liv Portfolio - Família';

  readonly config$ = of(this.configService.config);

  constructor(
    private configService: ConfigService,
    private store: Store,
    windowEvents: WindowEventsService
  ) {
    windowEvents.combinedEvents$.subscribe((event) => {
      if (typeof event === 'boolean') {
        // boolean evento de mudança
        if (!event) {
          //voltou para a aba
          this.store.dispatch(new SetStartActivityTimeAction()).subscribe();
        } else {
          //saiu da aba
          this.store.dispatch(new UpdateLastActivityTimeAction()).subscribe();
          this.store
            .dispatch(new SaveActivityTimeAction('visibility'))
            .subscribe();
        }
      } else {
        this.store.dispatch(new SaveActivityTimeAction('unload')).subscribe();
      }
    });
  }
}
