import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetCategoryAction } from './category.actions';

export interface CategoryStateModel {
  color: string;
  img: {
    src: string;
    alt?: string | null;
  } | null;
  title: string | null;
  description: string | null;
}
const CATEGORY_STATE_TOKEN = new StateToken<CategoryStateModel>('category');

@State<CategoryStateModel>({
  name: CATEGORY_STATE_TOKEN,
  defaults: {
    color: '#000',
    img: null,
    title: null,
    description: null
  }
})
@Injectable()
export class CategoryState {
  @Selector() static category(category: CategoryStateModel) {
    return category;
  }

  @Selector() static color({ color }: CategoryStateModel) {
    return color;
  }

  @Action(SetCategoryAction) setCategory(
    { patchState }: StateContext<CategoryStateModel>,
    { payload }: SetCategoryAction
  ): void {
    patchState({ ...payload });
  }
}
