export class SaveActivityTimeAction {
  static readonly type = '[Activity] Save activity time';
  constructor(public reason: string) {}
}

export class SetStartActivityTimeAction {
  static readonly type = '[Activity] Update activity time';
  constructor() {}
}

export class UpdateLastActivityTimeAction {
  static readonly type = '[Activity] Update activity time';
  constructor() {}
}
