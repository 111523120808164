import { HttpErrorResponse } from '@angular/common/http';
import { throwError, timer } from 'rxjs';
import { RetryConfig } from 'rxjs/operators';

interface GenericRetryStrategy {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
}

export function shouldRetry({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: GenericRetryStrategy = {}): RetryConfig {
  return {
    count: maxRetryAttempts,
    delay(error, retryAttempt) {
      const err = error as HttpErrorResponse;
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.some((statusCode) => statusCode === err.status)
      ) {
        return throwError(() => err);
      }
      return timer(retryAttempt * scalingDuration);
    }
  } as RetryConfig;
}
